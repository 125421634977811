let cart = {
	directives: {
		init: {
			bind: function(el, binding, vnode) {
				// This allows setting initial data values from html
				// Example: v-init:field1="1"
				console.log(binding.value);
				if (binding.value !== undefined ) {
					console.log(binding)
					console.log(binding.modifiers)
					Object.keys(binding.modifiers).length > 0 ? vnode.context[binding.arg][Object.keys(binding.modifiers)[0]] = binding.value : vnode.context[binding.arg] = binding.value;
				}
			}
		}
	},
	data: {
		items: [],
	},
	methods: {
		increase : function (itemIndex) {
			this.items[itemIndex] = this.items[itemIndex]+1;
		},
		decrease : function (itemIndex) {
			if (this.items[itemIndex] > 0) {
				this.items[itemIndex] = this.items[itemIndex]-1;
			}
		},
		checkout : function() {

		}
	},
	mounted: function () {
		if (this.$refs.items) {
			let lineItems =  JSON.parse(this.$refs.items.dataset.items);
			for (let i = 0; i < lineItems.length; i++) {
				this.items[i] = lineItems[i].qty;
				//this.items.splice(i, 1, lineItems[i].qty)
			}
		}
	},
	computed: {

	}
};

export default cart;