import { createApp } from 'vue'
import '../scss/styles.scss'
import checkout from './marketing/checkout.js';
import cart from './marketing/cart.js';
import product from './marketing/product.js';
import axios from 'axios';
import { stringify } from 'qs';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

const marketing = createApp({
  data() {
    return {
      count: 0
    }
  },
  mixins: [checkout,cart,product],
  delimiters: ['[[', ']]'],
  methods : {
    showModal(num) {
      document.querySelector('#modal-'+num).classList.remove('hidden');
    }
  }
})

const axiosInstance = axios.create({
  transformRequest: [
    function (data) {
      return stringify(data)
    },
  ],
})
window.axios = axiosInstance

marketing.mount('#site');


$(document).ready(function () {
  $('#tabs div').hide()
  $('#tabs div:first').show()
  $('#tabs ul li:first').addClass('active')

  $('#tabs ul li a').click(function () {
    $('#tabs ul li a').removeClass('active')
    $(this).addClass('active')
    var currentTab = $(this).attr('href')
    $('#tabs div').hide()
    $(currentTab).show()
    return false
  })
  $('.closeModal').on('click', function () {
    $(this).parent().parent().parent().parent().addClass('hidden')
  })

  $('button.menu-button').click(function () {
    document.querySelector('nav.main').classList.toggle('hidden')
    this.classList.toggle('fixed')
    this.classList.toggle('absolute')
  })

})


