let checkout = {
  data: {
    fields: {},
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    password: "",
    licensedClinician: "",
    creditCardFirstName: "",
    creditCardLastName: "",
    creditCardError: "",
    card: undefined,
    terms: 0,
    paymentMethodId: "",
    submitted: false,
    companyId: "",
    stripe: undefined,
    elements: undefined,
    hasErrors: false,
    csrfToken: window.csrfToken,
  },
  methods: {
    async submitCheckout(event) {
      this.submitted = true;

      try {
        if (this.creditCardError === "" && this.validateAll()) {
          let response = await this.stripe.createPaymentMethod(
            "card",
            this.card,
            {
              billing_details: {
                name: `${this.creditCardFirstName} ${this.creditCardLastName}`,
                email: this.email,
              },
            }
          );
          if (response.error) {
            throw new Error(response.error.message);
          }
          // Add the payment source token to the form.
          this.paymentMethodId = response.paymentMethod.id;

          window.axios.defaults.headers.common["X-CSRF-Token"] =
            window.csrfToken;
          response = await window.axios.post(
            "/actions/site-module/shop/add-user",
            this.userData
          );
          if (response.status !== 200) {
            throw new Error("add user failed");
          } else if (response.data === "Email taken") {
            this.creditCardError =
              'There is already an account for this email address. Please <a href="/login">log in</a> or <a href="/login/reset-password">reset your password</a>';
          } else {
            this.companyId = response.data.companyId;
            window.axios.defaults.headers.common["X-CSRF-Token"] =
              response.data.csrfToken;
            this.csrfToken = response.data.csrfToken;
            response = await window.axios.post(
              "/actions/commerce/cart/update-cart",
              { email: this.email }
            );
            if (response.status !== 200) {
              throw new Error("update cart failed");
            }
            await this.$nextTick(() => {
              // Execute submit on next tick to make sure all form values have been properly updated.
              event.target.submit();
            });
          }
        }
      } catch (e) {
        this.logError("error in checkout: " + error.message);
        this.creditCardError =
          "Sorry! The checkout failed due to a technical issue. Please reload the page and try again. If this does not help, please contact us for assistance.";
      } finally {
        this.submitted = false;
      }
    },
    updateErrorMessage(event) {
      this.creditCardError = event.error ? event.error.message : "";
      this.submitted = false;
    },
    logError(message) {
      window.axios.post("/actions/site-module/shop/log-error", {
        message: message,
      });
    },
    validateAll() {
      const rules = {
        firstName: {
          rules: ["required"],
          label: "First name",
        },
        lastName: {
          rules: ["required"],
          label: "Last name",
        },
        company: {
          rules: ["required"],
          label: "Company",
        },
        email: {
          rules: ["required", "email"],
          label: "Email",
        },
        password: {
          rules: ["required"],
          label: "Password",
        },
        licensedClinician: {
          rules: ["required"],
          label: "",
        },
        creditCardFirstName: {
          rules: ["required"],
          label: "Card Holder First Name",
        },
        creditCardLastName: {
          rules: ["required"],
          label: "Card Holder Last Name",
        },
        terms: {
          rules: ["checked"],
          label: "Terms",
        },
      };
      let anyInvalid = false;
      Object.entries(rules).forEach(([handle, field]) => {
        let invalid = false;
        if (field.rules.includes("required")) {
          if (this[handle] < 1) {
            invalid = true;
          }
        }
        if (field.rules.includes("checked")) {
          if (this[handle] < 1) {
            invalid = true;
          }
        }
        this.fields[handle] = {
          validated: true,
          invalid: invalid,
        };
        anyInvalid = invalid ? true : anyInvalid;
        this.hasErrors = anyInvalid;
      });
      return !anyInvalid;
    },
    fieldHasError(field) {
      return !!(
        this.fields[field] !== undefined &&
        this.fields[field].validated &&
        this.fields[field].invalid
      );
    },
  },
  mounted: function () {
    if (this.$refs.card) {
      let style = {
        base: {
          fontSize: "16px",
          color: "#32325d",
        },
      };
      this.stripe = Stripe(this.$refs.card.dataset.publishableStripeKey);
      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", { style: style });
      this.card.addEventListener("change", this.updateErrorMessage);
      this.card.mount(this.$refs.card);
    }
  },
  computed: {
    userData: function () {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        username: this.username,
        email: this.email,
        licensedClinician: this.licensedClinician,
        password: this.password,
      };
    },
  },
};

export default checkout;
