let product = {
	data: {
		qty : 11,
		prices : undefined
	},
	methods: {

	},
	mounted: function () {
		if (this.$refs.prices) {
			this.prices =  JSON.parse(this.$refs.prices.dataset.prices);
		}
	},
	computed: {
		total : function () {
			let total;
			if (this.prices === undefined) {
				return 0;
			} else {
				for (let [min, price] of Object.entries(this.prices)) {
					min = Number(min.substring(2));
					if (this.qty >= min) {
						total = (this.qty * price).toFixed(2);
					}
				}
				return total;
			}

			//return this.qty > 50 ? this.qty * 1.7 : this.qty > 10 ? this.qty * 2 : this.qty * 2.7;
		}
	}
};

export default product;